import { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Loader from "./components/shared/Loader";
import Alltransactions from "./pages/dashboard/Alltransactions";
const HealthCheckupList = lazy(() =>
  import("./pages/healthcheckup/HealthCheckupList")
);
const BulkOrders = lazy(() => import("./pages/bulkOrders"));
const IPRList = lazy(() => import("./pages/ipr/iprList"));
const IPRDetail = lazy(() => import("./pages/ipr/iprDetail"));
const NotFound = lazy(() => import("./pages/notFound"));
const ProficornHomeScreen = lazy(() => import("./pages/proficorn"));
const ComingSoon = lazy(() => import("./pages/comingSoon"));
const GlobalFaq = lazy(() => import("./pages/faq"));
const HealthCheckupForm = lazy(() => import("./pages/healthCheckupForms"));
const ExpressCheckout = lazy(() => import("./pages/expressCheckout"));
const ContactUs = lazy(() => import("./pages/contactUs"));
const Logout = lazy(() => import("./pages/logout"));
const CancelationPolicy = lazy(() => import("./pages/cancelationPolicy"));
const PrivacyPolicy = lazy(() => import("./pages/privacyPolicy"));
const HomeScreen = lazy(() => import("./pages/homeScreen"));
const ESign = lazy(() => import("./pages/eSign"));
const AffiliateProgramme = lazy(() => import("./pages/affiliateProgramme"));
const Contractdrafting = lazy(() => import("./pages/contractDrafting"));
const ContractdraftingForm = lazy(() => import("./pages/contractDraftingForm"));
const ContractReview = lazy(() => import("./pages/contractReview"));
const ContractReviewForm = lazy(() => import("./pages/contractReviewForm"));
const IPRForm = lazy(() => import("./pages/iprForm"));
const Legalconsultation = lazy(() => import("./pages/legalConsultation"));
const LegalNotice = lazy(() => import("./pages/legalNotice"));
const Posh = lazy(() => import("./pages/posh"));
const Healthcheckup = lazy(() => import("./pages/healthcheckup"));
const AboutUs = lazy(() => import("./pages/aboutUs"));
const IPR = lazy(() => import("./pages/ipr"));
const SignUp = lazy(() => import("./pages/signup"));
const Login = lazy(() => import("./pages/login"));
const ESignOnlyMe = lazy(() => import("./components/esign/onlyme"));
const ESignMeAndOthers = lazy(() => import("./components/esign/meandothers"));
const ESignOthers = lazy(() => import("./components/esign/onlyothers"));
const MembershipSummary = lazy(() =>
  import("./pages/membership/MembershipSummary")
);
const GoogleLogin = lazy(() => import("./components/login/GoogleLogin"));
const LoginError = lazy(() => import("./pages/loginError"));
const TryLegal = lazy(() => import("./pages/trylegal"));
const Dashboard = lazy(() => import("./pages/dashboard"));
const DashboardBody = lazy(() => import("./pages/dashboard/Dashboard"));
const MyProfile = lazy(() => import("./pages/myProfile/Profile"));
const ESignList = lazy(() => import("./pages/eSign/eSignList"));
const ESignDetail = lazy(() => import("./pages/eSign/eSignDetail"));
const TermOfUse = lazy(() => import("./pages/termOfUse"));
const CouponsList = lazy(() => import("./pages/userCoupons"));
const ContractReviewList = lazy(() =>
  import("./pages/contractReview/contractReviewList")
);
const ContractDraftList = lazy(() =>
  import("./pages/contractDrafting/contractDraftList")
);
const ContractReviewDetail = lazy(() =>
  import("./pages/contractReview/contractReviewDetail")
);
const ContractDraftDetail = lazy(() =>
  import("./pages/contractDrafting/contractDraftDetail")
);
const Pricing = lazy(() => import("./pages/pricing"));
function App() {
  return (
    <div className="bg-white">
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route
              path="/affiliate-programme"
              element={<AffiliateProgramme />}
            />
            <Route path="/contract-drafting" element={<Contractdrafting />} />
            <Route
              path="/contract-drafting-form"
              element={<ContractdraftingForm />}
            />
            <Route path="/contract-review" element={<ContractReview />} />
            <Route path="/ipr-form" element={<IPRForm />} />
            <Route
              path="/contract-review-form"
              element={<ContractReviewForm />}
            />
            <Route path="/legal-consultation" element={<Legalconsultation />} />
            <Route path="/legal-notice" element={<LegalNotice />} />
            <Route path="/posh" element={<Posh />} />
            <Route path="/health-checkup" element={<Healthcheckup />} />
            <Route path="/ipr" element={<IPR />} />
            <Route path="/cancelation-policy" element={<CancelationPolicy />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/term-of-use" element={<TermOfUse />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/try-legal" element={<TryLegal />} />
            <Route
              path="/health-checkup-form"
              element={<HealthCheckupForm />}
            />
            <Route path="/e-sign" element={<ESign />} />
            <Route path="/e-sign/onlyme" element={<ESignOnlyMe />} />
            <Route path="/e-sign/meandother" element={<ESignMeAndOthers />} />
            <Route path="/e-sign/onlyothers" element={<ESignOthers />} />
            <Route path="/bulk-orders-checkout" element={<BulkOrders />} />
            <Route path="/faq" element={<GlobalFaq />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/callback/google" element={<GoogleLogin />} />
            <Route path="/log-out" element={<Logout />} />
            <Route path="/login-error" element={<LoginError />} />
            <Route path="/app" element={<Dashboard />}>
              <Route path="/app/dashboard" element={<DashboardBody />} />
              <Route
                path="/app/dashboard/alltransactions"
                element={<Alltransactions />}
              />
              <Route path="/app/my-profile" element={<MyProfile />} />
              <Route path="/app/e-sign" element={<ESignList />} />
              <Route path="/app/coupons" element={<CouponsList />} />
              <Route
                path="/app/health-checkup"
                element={<HealthCheckupList />}
              />
              <Route path="/app/ipr" element={<IPRList />} />
              <Route path="/app/ipr/:id" element={<IPRDetail />} />
              <Route path="/app/e-sign/:order_id" element={<ESignDetail />} />
              <Route
                path="/app/contract-review"
                element={<ContractReviewList />}
              />
              <Route
                path="/app/contract-drafting"
                element={<ContractDraftList />}
              />
              <Route
                path="/app/contract-review/:id"
                element={<ContractReviewDetail />}
              />
              <Route
                path="/app/contract-drafting/:id"
                element={<ContractDraftDetail />}
              />
            </Route>
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/express-checkout" element={<ExpressCheckout />} />
            <Route path="/membership/summary" element={<MembershipSummary />} />
            <Route path="/comingsoon" element={<ComingSoon />} />
            <Route path="/pace" element={<ProficornHomeScreen />} />
            <Route path="/" element={<HomeScreen />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
